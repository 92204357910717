<template>
  <div @mouseover="columnMouseOver" @mouseleave="columnMouseLeave" :class="b()">
    <el-table
      ref="table"
      class="avuedata-table"
      @cell-click="cellClick"
      :data="dataChart"
      :height="height"
      :border="option.border"
      :cellStyle="cellStyle"
      :row-style="rowStyle"
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle">
      <el-table-column
        type="index"
        label="#"
        header-align="center"
        align="center"
        v-if="option.index"
        width="60">
        <span slot-scope="{$index}">{{$index+1}}</span>
      </el-table-column>
      <template v-for="(item,index) in option.column">
        <el-table-column
          v-if="item.hide!==true"
          show-overflow-tooltip
          :key='index'
          :prop="item.prop"
          :label="item.label"
          :width="item.width">
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import create from "../../create";
export default create({
  name: "table",
  data() {
    return {
      headerHeight: '',
      height: '',
      scrollCheck: "",
    };
  },
  watch: {
    scrollSpeed() {
      this.setTime();
    },
    scroll: {
      handler() {
        this.setTime();
      },
    }
  },
  computed: {
    scrollTime() {
      return this.option.scrollTime
    },
    scrollSpeed() {
      return this.option.scrollSpeed || 1
    },
    scroll() {
      return this.option.scroll
    },
    cellHeight() {
      return parseInt((this.height - this.headerHeight) / this.option.count)
    }
  },
  props: {
    option: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      setTimeout(() => {
        this.setTime();
      }, this.scrollTime)
    })
  },
  methods: {
    cellClick(row, column, cell, event) {
      this.updateClick(row);
      this.clickFormatter && this.clickFormatter({
        type: column,
        item: cell,
        data: this.dataChart
      }, this.getItemRefs());
    },
    setTime() {
      clearInterval(this.scrollCheck);
      this.height = parseInt(this.$el.clientHeight);
      this.headerHeight = parseInt(this.$refs.table.$refs.headerWrapper.clientHeight)
      const table = this.$refs.table
      const divData = table.bodyWrapper
      const speed = this.scrollSpeed
      let top = 0
      if (this.scroll) {
        this.scrollCheck = setInterval(() => {
          top = top + speed
          divData.scrollTop += speed;
          // console.log(' divData.clientHeight + divData.scrollTop >= divData.scrollHeight | ', divData.clientHeight, divData.scrollTop, divData.scrollHeight)
          if (divData.clientHeight + Math.floor(divData.scrollTop + 1) >= divData.scrollHeight) {
            setTimeout(() => {
              divData.scrollTop = 0
            }, 1000);
            clearInterval(this.scrollCheck);
            setTimeout(() => {
              this.setTime()
            }, 3000)
            return
          }
          if (top >= this.cellHeight && this.scrollTime) {
            divData.scrollTop = divData.scrollTop - (top - this.cellHeight)
            clearInterval(this.scrollCheck);
            setTimeout(() => {
              this.setTime()
            }, this.scrollTime)
          }
        }, 20)
      } else {
        divData.scrollTop = 0
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        padding: 0,
        height: this.setPx(this.cellHeight),
        fontSize: this.setPx(this.option.bodyFontSize),
        color: this.option.bodyColor,
        textAlign: column.type == 'index' ? 'center' : this.option.bodyTextAlign,
        backgroundColor: rowIndex % 2 == 0 ? this.option.othColor : this.option.nthColor,
      }
    },
    rowStyle({ rowIndex }) {
      return {
        backgroundColor: 'transparent'
      }
    },
    headerRowStyle() {
      return {
        backgroundColor: this.option.headerBackground
      }
    },
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: this.setPx(this.option.headerFontSize),
        backgroundColor: this.option.headerBackground,
        color: this.option.headerColor,
        textAlign: column.type == 'index' ? 'center' : this.option.headerTextAlign
      }
    },
    columnMouseOver() {
      if (!this.scroll || (this.scroll && !this.option.mouseOverStop)) return
      clearInterval(this.scrollCheck);
    },
    columnMouseLeave() {
      if (!this.scroll || (this.scroll && !this.option.mouseOverStop)) return
      this.setTime()
    }
  }
});
</script>

<style lang="scss">
.avuedata-table.el-table thead th {
  background: rgba($color: #000000, $alpha: 0) !important;
}

.avuedata-table.el-table .el-table__header-wrapper {
  margin-bottom: 5px;
}
</style>
